<template>
  <div class="container">
    <div class="banner">
      <swiper ref="swiper" />
    </div>
    <div class="community">
      <div class="navtitle">
        <div class="title">年轻人的潮流社区</div>
        <div class="sub_title">A TRENDY COMMUNITY FOR YOUNG PEOPLE</div>
        <div class="horizontal_line"></div>
      </div>

      <div class="logo_out">
        <div
          class="left_btn"
          @click="changeSlide('left')"
          v-if="logoList.length > 8"
        >
          ←
        </div>
        <div class="logo_list" :class="logoList.length <= 8 ? 'is_slide' : ''">
          <div
            :style="{ transform: `translateX(${translateX}px)` }"
            class="list_item"
            :class="item.active ? 'active_bg' : ''"
            v-for="(item, index) in logoList"
            :key="index"
            @click="changeLogo(index)"
          >
            <img :src="item.icon" />
          </div>
        </div>
        <div
          class="right_btn"
          @click="changeSlide('right')"
          v-if="logoList.length > 8"
        >
          →
        </div>
      </div>

      <div class="main">
        <div class="left_btn" @click="changeCurrent('left')">←</div>
        <div class="introduce" v-if="!current">
          <div class="introduce_l">
            <div style="position: relative">
              <img
                :src="barndList[current]?.details?.images[0]?.src"
                :title="barndList[current]?.title"
                class="leftimg"
              />
              <div class="incentive">
                <div style="transform: skewX(10deg)">
                  <span> “给逐梦城市的奋斗者 </span>
                  <span> 一个温暖的家” </span>
                </div>
              </div>
            </div>
          </div>
          <div class="introduce_r">
            <div class="title">{{ barndList[current]?.title }}</div>
            <div class="content">
              {{ barndList[current]?.intro }}
            </div>
            <div class="area">
              <span
                v-for="(item, index) in barndList[current]?.details?.cities"
                :key="index"
              >
                {{ handleArea(item) }}
              </span>
            </div>
            <div>其他一二线城市持续扩展中</div>
            <div class="icon_list">
              <div class="list_item">
                <img src="../../assets/image/cs.png" />
                <span class="count">
                  {{ barndList[current]?.details?.cities?.length }}
                </span>
                <span class="tip">大入驻一线及二线城市</span>
              </div>
              <div class="list_item">
                <img src="../../assets/image/md.png" />
                <span class="count">
                  {{ barndList[current]?.details?.store_num }}+
                </span>
                <span class="tip">运营门店</span>
              </div>
              <div class="list_item">
                <img src="../../assets/image/fy.png" />
                <span class="count"
                  >{{ barndList[current]?.details?.user_num }}+</span
                >
                <span class="tip">城市青年用户</span>
              </div>
            </div>
          </div>
        </div>
        <div class="brand" v-else>
          <div class="brand_l">
            <div class="count">{{ getNumber(current) }}</div>
            <div class="bg"></div>
            <img
              :src="barndList[current]?.details?.images[0]?.src"
              :title="barndList[current]?.title"
            />
          </div>
          <div class="brand_r">
            <div>
              <div class="title">{{ barndList[current]?.title }}</div>
              <div class="content">
                {{ barndList[current]?.intro }}
              </div>
            </div>
            <div class="btn" @click="toBrand(barndList[current]?.brand_id)">
              了解更多 →
            </div>
          </div>
        </div>
        <div class="right_btn" @click="changeCurrent('right')">→</div>
      </div>
    </div>
    <div class="life">
      <div class="content">
        <div class="navtitle">
          <div class="title">品质，时尚的生活环境</div>
          <div class="sub_title">QUALITY，FASHIONABLE LIVING ENVIRONMENT</div>
          <div class="horizontal_line"></div>
        </div>
        <div class="btn_list">
          <div
            class="btn"
            :class="item.active ? 'active' : ''"
            v-for="(item, index) in houseTypeList"
            :key="index"
            @click="changTabs(index, 'houseTypeList')"
          >
            {{ item.layout_group }}
          </div>
        </div>
        <div class="fy_list">
          <div
            class="btn_item"
            @click="showDetail(item.fx_id)"
            v-for="(item, index) in houseTypeList[getHouseTypeIndex]?.datalist"
            :key="index"
          >
            <img v-lazy="item.cover_picture?.path" />
            <div class="room_info">
              <span class="room_info_t">{{
                houseTypeList[getHouseTypeIndex]?.layout_group
              }}</span>
              <span class="room_info_subt">{{ item.fx_name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="for_us">
      <div class="navtitle">
        <div class="title">选择我们的理由</div>
        <div class="sub_title">REASONS FOR CHOOSING US</div>
        <div
          class="horizontal_line"
          style="background: rgba(255, 255, 255, 0.2)"
        ></div>
      </div>
      <div class="us_list">
        <div class="us_list_item">
          <img src="../../assets/image/pz.png" />
          <span>实惠品质好房</span>
        </div>
        <div class="us_list_item">
          <img src="../../assets/image/gjfw.png" />
          <span>专业管家服务</span>
        </div>
        <div class="us_list_item">
          <img src="../../assets/image/lszz.png" />
          <span>品牌连锁保障</span>
        </div>
        <div class="us_list_item">
          <img src="../../assets/image/yzs.png" />
          <span>一站式省心</span>
        </div>
      </div>
    </div>
    <div class="news">
      <div class="navtitle">
        <div class="title">新闻资讯</div>
        <div class="sub_title">NEWS AND INFORMATION</div>
        <div class="horizontal_line" style="background: #eeeeee"></div>
      </div>
      <div class="news_btn_list">
        <div
          class="btn"
          :class="item.active ? 'active' : ''"
          v-for="(item, index) in articlesList"
          :key="index"
          @click="changTabs(index, 'articlesList')"
        >
          {{ item.category_group }}
        </div>
      </div>
      <div class="loadmore" @click="toNewsList">
        更多{{ getCurrentTabLabel }} <img src="../../assets/image/gd.png" />
      </div>
      <div class="news_card">
        <div class="card_list">
          <div
            class="card_list_item"
            v-for="item in articlesList[getNewsIndex]?.datalist"
            :key="item.article_id"
            @click="toNewsDetail(item.article_id)"
          >
            <div class="img_out">
              <img v-lazy="item.cover_image" />
            </div>
            <span class="title" :title="item.title">{{ item.title }}</span>
            <div class="glide_line"></div>
            <span class="content" :title="item.summary">
              {{ item.summary }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <room-detail ref="roomDetail" :isShow="isShow" />
    <loading v-if="isLoading" />
  </div>
</template>
<script>
import { getIndex } from "@/api/index.js";
import roomDetail from "@/components/roomDetail";
import swiper from "@/components/swiper/index";

export default {
  components: {
    roomDetail,
    swiper,
  },
  data() {
    return {
      current: 0,
      logCurrent: 0,
      logoList: [],
      translateX: 0,
      isShow: false,
      //新闻资讯
      articlesList: [],
      //房源类型
      houseTypeList: [],
      //品牌
      barndList: [],
      isLoading: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      try {
        this.isLoading = true;
        let data = {
          article_group: ["运东新闻", "行业新闻", "社会政策"],
        };
        const res = await getIndex(data);
        //轮播图
        this.$refs.swiper.bannerList = res.data.banners;
        this.$refs.swiper.onChangeSwiper();
        //新闻咨询
        this.addActive(res.data, "articles");
        this.articlesList = res.data.articles;
        //房型
        this.addActive(res.data, "house_types");
        res.data.house_types.forEach((item) => {
          item.datalist = item.dtalist?.slice(0, 5);
        });
        this.houseTypeList = res.data.house_types;
        //品牌
        this.addActive(res.data, "brands");
        this.logoList = res.data.brands.map((item) => {
          return {
            icon: item.logo,
            selectIcon: item.details?.logo_checked,
            active: item.active,
          };
        });
        this.barndList = res.data.brands;
      } finally {
        this.isLoading = false;
      }
    },
    addActive(res, list) {
      res[list].forEach((item, index) => {
        if (!index) {
          item["active"] = true;
        } else {
          item["active"] = false;
        }
      });
    },
    changeLogo(i) {
      this.logoList.forEach((item, index) => {
        if (i == index) {
          item.active = true;
          this.current = index;
        } else {
          item.active = false;
        }
      });
    },
    changTabs(i, type) {
      this[type].forEach((item, index) => {
        if (i == index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    },
    changeCurrent(type) {
      if (type == "left") {
        if (!this.current) return;
        this.changeLogo(this.current - 1);
      } else if (type == "right") {
        if (this.current == this.logoList.length - 1) return;
        this.changeLogo(this.current + 1);
      }
    },
    changeSlide(type) {
      let width = 178;
      if (type == "left") {
        if (!this.logCurrent) return;
        this.translateX = this.translateX + width;
        this.logCurrent = this.logCurrent - 1;
      } else if (type == "right") {
        if (this.logoList.length - this.logCurrent <= 8) return;
        this.translateX = this.translateX - width;
        this.logCurrent = this.logCurrent + 1;
      }
    },
    showDetail(id) {
      this.isShow = true;
      this.$refs.roomDetail.detailId = id;
      this.$refs.roomDetail.translateX = 0;
      this.$refs.roomDetail.current = 0;
      this.$refs.roomDetail.initData();
    },
    toNewsDetail(id) {
      this.$router.push(`/newsDetail?id=${id}`);
    },
    toBrand(id) {
      this.$router.push(`/brandDetail?id=${id}`);
    },
    toNewsList() {
      this.$router.push("/news");
    },
  },
  computed: {
    getNewsIndex() {
      return this.articlesList.findIndex((item) => {
        return item.active;
      });
    },
    getHouseTypeIndex() {
      return this.houseTypeList.findIndex((item) => {
        return item.active;
      });
    },
    getNumber() {
      return (num) => {
        if (num <= 9) {
          return "0" + num;
        } else {
          return num;
        }
      };
    },
    handleArea() {
      return (list) => {
        return list[list.length - 1];
      };
    },
    getCurrentTabLabel() {
      let currentNmae = "";
      this.articlesList.forEach((item) => {
        if (item.active) {
          currentNmae = item.category_group;
        }
      });
      return currentNmae;
    },
  },
};
</script>
<style scoped lang="scss">
$width: 1600px;
.active_bg {
  // background-color: #ffa71d !important;
  border: 1px solid #ffa71d !important;
  box-sizing: border-box;
}
.container {
  width: 100vw;
  height: auto;
  .banner {
    width: 100%;
  }
  .community {
    width: $width;
    margin: 0 auto;
    background: #fff;
    padding: 80px 50px 80px 50px;
    box-sizing: border-box;
    .logo_out {
      width: 100%;
      display: flex;
      column-gap: 20px;
      align-items: center;
    }
    .logo_list {
      width: 100%;
      display: flex;
      margin: 50px 0;
      overflow: hidden;
      .list_item {
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-right: 78px;
        border: 1px solid #eeeeee;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        img {
          width: 90px;
          height: 90px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
    .is_slide {
      padding-left: 75px;
      box-sizing: border-box;
    }
    .main {
      display: flex;
      align-items: center;
      column-gap: 20px;
      .left_btn,
      .right_btn {
        width: 46px;
        height: 46px;
        border: 1px solid #eeeeee;
        flex-shrink: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999999;
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE 10+/Edge */
        user-select: none;
      }
      .introduce {
        box-sizing: border-box;
        display: flex;
        column-gap: 170px;
        padding: 40px 20px 0 20px;
        flex: 1;
        &_l {
          flex: 1;
          position: relative;
          // &::before {
          //   display: block;
          //   content: "";
          //   width: 40px;
          //   height: 110px;
          //   background: #ffa71d;
          //   position: absolute;
          //   left: -20px;
          //   top: 0;
          //   z-index: 9;
          // }
          .incentive {
            width: 380px;
            height: 120px;
            background: #ffa71d;
            position: absolute;
            right: -80px;
            bottom: -36px;
            transform: skewX(-10deg);
            display: flex;
            color: #fff;
            font-weight: bold;
            font-size: 22px;
            justify-content: center;
            flex-direction: column;
            padding-left: 42px;
            box-sizing: border-box;
            span {
              display: inline-block;
            }
            span:last-child {
              padding-left: 142px;
              padding-top: 6px;
              box-sizing: border-box;
            }
          }
          .leftimg {
            width: 620px;
            height: 510px;
            object-fit: cover;
          }
        }
        &_r {
          flex: 1;
          color: #333;
          .title {
            font-size: 32px;
            font-weight: bold;
            position: relative;
            padding-left: 16px;
            box-sizing: border-box;
            &::after {
              content: "";
              width: 6px;
              height: 32px;
              background: #ffa71d;
              position: absolute;
              left: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .content {
            color: #666;
            font-size: 14px;
            margin: 30px 0 20px 0;
            text-indent: 2em;
          }
          .area {
            display: flex;
            column-gap: 40px;
            font-size: 18px;
            margin-bottom: 20px;
            flex-wrap: wrap;
            row-gap: 20px;
            span {
              flex-shrink: 0;
            }
          }
          .icon_list {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            .list_item {
              display: flex;
              align-items: center;
              img {
                margin-right: 31px;
                width: 74px;
                height: 74px;
              }
              .count {
                margin-right: 15px;
                font-size: 38px;
                color: #ffa71d;
              }
              .tip {
                font-size: 16px;
              }
            }
          }
        }
      }
      .brand {
        width: $width;
        margin: 0 auto;
        background: #fff;
        padding: 80px 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        flex: 1;
        column-gap: 80px;
        &_l {
          flex: 1;
          position: relative;
          .bg {
            width: 580px;
            height: 410px;
            background: #eeeeee;
            transform: rotate(-10deg);
          }
          img {
            width: 580px;
            height: 410px;
            object-fit: cover;
            position: absolute;
            top: 0px;
          }
          .count {
            width: 120px;
            height: 80px;
            background: #ffa71d;
            font-size: 56px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -15px;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &_r {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-size: 32px;
            font-weight: bold;
            position: relative;
            padding-left: 16px;
            box-sizing: border-box;
            margin-bottom: 30px;
            &::after {
              content: "";
              width: 6px;
              height: 32px;
              background: #ffa71d;
              position: absolute;
              left: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .content {
            color: #666;
            font-size: 14px;
            text-indent: 2em;
            line-height: 24px;
          }
          .btn {
            width: 130px;
            height: 46px;
            background: #ffa71d;
            border-radius: 4px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }

  .life {
    width: 100vw;
    background: #f5f5f5;
    .content {
      width: $width;
      margin: 0 auto;
      padding: 80px 50px;
      box-sizing: border-box;
    }
    .btn_list {
      display: flex;
      margin: 40px;
      justify-content: center;
      column-gap: 20px;
      .btn {
        padding: 12px 44px;
        background: #fff;
        border-radius: 4px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666666;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid #eeeeee;
      }
      .active {
        background: #ffa71d !important;
        color: #fff !important;
      }
    }
    .fy_list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      justify-content: space-between;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
      }
      img:hover {
        transform: scale(1.1);
      }
      .btn_item {
        max-width: 33%;
        height: 344px;
        flex: 1;
        object-fit: cover;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        .room_info {
          color: #fff;
          display: flex;
          flex-direction: column;
          position: absolute;
          bottom: 24px;
          left: 24px;
          &_t {
            font-size: 22px;
            font-weight: bold;
          }
          &_subt {
            font-size: 16px;
          }
        }
      }
      .btn_item:nth-child(1),
      .btn_item:nth-child(2) {
        min-width: 738px;
        width: 738px;
        height: 524px;
        margin-bottom: 20px;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }
  .for_us {
    width: 100vw;
    height: 500px;
    background: url("../../assets/image/xzwm.png") 100% 100% no-repeat;
    padding: 80px 0;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    .us_list {
      display: flex;
      color: #fff;
      font-size: 18px;
      justify-content: center;
      column-gap: 220px;
      margin-top: 60px;
      // animation-name: fadeInLeft;
      // animation-duration: 2s;
      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 114px;
          height: 114px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .news {
    width: $width;
    margin: 0 auto;
    background: #fff;
    padding: 80px 50px;
    box-sizing: border-box;
    &_btn_list {
      display: flex;
      margin: 40px;
      justify-content: center;
      column-gap: 20px;
      .btn {
        padding: 12px 44px;
        background: #fff;
        border-radius: 4px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666666;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid #eeeeee;
      }
      .active {
        background: #ffa71d !important;
        color: #fff !important;
      }
    }
    .loadmore {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      align-items: center;
      font-size: 14px;
      color: #666;
      column-gap: 10px;
      cursor: pointer;
      img {
        width: 22px;
        height: 14px;
      }
    }
    .news_card {
      .card_list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        &_item {
          overflow: hidden;
          cursor: pointer;
          .img_out {
            overflow: hidden;
            height: 400px;
            img {
              width: 100%;
              height: 400px;
              object-fit: cover;
              transition: all 0.3s ease;
              &:hover {
                transform: scale(1.1);
              }
            }
          }
          .title {
            color: #333;
            font-size: 20px;
            margin: 20px 0;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              color: #ffa71d;
            }
          }
          .glide_line {
            width: 30px;
            height: 4px;
            background: #ffa71d;
            margin-bottom: 18px;
          }
          .content {
            color: #666666;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
</style>
