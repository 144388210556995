<template>
  <div
    class="swiper-container"
    :style="{ '--width': width, '--time': time / 1000 + 's' }"
  >
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(item, index) in bannerList"
        :key="index"
      >
        <img :src="item.src" :title="item.title" />
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>

    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

let vm = null;
document.addEventListener("visibilitychange", () => {
  // 回到当前tab标签
  if (document.visibilityState !== "hidden") {
    vm.onChangeWidth();
  }
});

export default {
  data() {
    return {
      width: "0",
      time: 5000, // 时间
      bannerList: [],
    };
  },
  created() {
    vm = this;
  },
  methods: {
    onChangeWidth() {
      this.time = 0;
      this.width = "0";
      setTimeout(() => {
        this.width = "100%";
        this.time = this.$options.data().time;
      }, 100);
    },
    onChangeSwiper() {
      const that = this;
      this.$nextTick(() => {
        new Swiper(".swiper-container", {
          loop: true, // 循环模式选项
          autoplay: {
            delay: this.time,
            stopOnLastSlide: false,
            disableOnInteraction: true,
            disableOnInteraction: false,
          },
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          on: {
            init: () => {
              that.onChangeWidth();
            },
            // 过渡开始时触发。可选Swiper实例作为参数，会首次加载一次
            transitionStart: () => {
              that.onChangeWidth();
            },
          },
          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          // 如果需要滚动条
          scrollbar: {
            el: ".swiper-scrollbar",
          },
          observer: true,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 700px;
}
.swiper-slide {
  background-color: #fff;
  color: #fff;
  font-size: 50px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

::v-deep .swiper-pagination {
  .swiper-pagination-bullet {
    overflow: hidden;
    border-radius: 20px !important;
    background-color: #fff;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    width: 100px !important;
    // 进度条
    &::before {
      content: "";
      display: block;
      width: var(--width);
      height: 100%;
      background: rgba($color: #ffa71d, $alpha: 0.6);
      transition: width var(--time) ease;
    }
  }
}
.swiper-button-prev {
  left: 35px;
  color: rgba($color: #ffa71d, $alpha: 1);
}
.swiper-button-next {
  right: 45px;
  color: rgba($color: #ffa71d, $alpha: 1);
}
</style>
